<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.avgAht.theme"
          v-bind:name="dataCards.avgAht.name"
          v-bind:value="secondsDuration(dataCards.avgAht.value)">
          <clock-outline-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.avgAsa.theme"
          v-bind:name="dataCards.avgAsa.name"
          v-bind:value="secondsDuration(dataCards.avgAsa.value)">
          <clock-outline-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.totalConversations.theme"
          v-bind:name="dataCards.totalConversations.name"
          v-bind:value="dataCards.totalConversations.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.inbound.theme"
          v-bind:name="dataCards.inbound.name"
          v-bind:value="dataCards.inbound.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.outbound.theme"
          v-bind:name="dataCards.outbound.name"
          v-bind:value="dataCards.outbound.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
    </div>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsByChannel"
          v-bind:name="'chart.conversations.by-channel'"
          v-bind:chart-id="'conversationsByChannel'"
          v-bind:chart-data="dataCharts.conversationsByChannel"
          v-bind:type="'stackedColumn'"
          v-on:change="chartRangeChanged"/>
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsReceivedVsAttended"
          v-bind:translatable="true"
          v-bind:name="'chart.conversations.received-vs-attended'"
          v-bind:chart-id="'conversationsReceivedVsAttended'"
          v-bind:chart-data="dataCharts.conversationsReceivedVsAttended"
          v-bind:type="'line'"
          v-on:change="chartRangeChanged"/>
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsByCategory"
          v-bind:name="'chart.conversations.by-category'"
          v-bind:chart-id="'conversationsByCategory'"
          v-bind:chart-data="dataCharts.conversationsByCategory"
          v-bind:type="'pie'"
          v-bind:type-loader="'piechart'"
          v-on:change="chartRangeChanged"/>
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsStatuses"
          v-bind:translatable="true"
          v-bind:time-format="true"
          v-bind:name="'chart.conversations.statuses'"
          v-bind:chart-id="'conversationsStatuses'"
          v-bind:chart-data="dataCharts.conversationsStatuses"
          v-bind:type="'line'"
          v-on:change="chartRangeChanged"/>
      </div>
    </div>
  </div>
</template>

<script>
import ConversationsDataCards from '@/graphql/queries/conversations/dataCards.gql';
import ConversationsByChannel from '@/graphql/queries/conversations/byChannel.gql';
import ConversationsReceivedVsAttended from '@/graphql/queries/conversations/receivedVsAttended.gql';
import ConversationsByCategory from '@/graphql/queries/conversations/byCategory.gql';
import ConversationsStatuses from '@/graphql/queries/conversations/statuses.gql';
import { executeQuery } from '@/utils/gql-api';

export default {
  components: {
    DataCard: () => import('@/components/DataCard.vue'),
    DataChart: () => import('@/components/DataChart.vue'),
  },
  props: {
    filters: {
      required: true,
    },
  },
  data() {
    return {
      dataCards: {
        avgAht: {
          theme: 'blue',
          name: 'chart.card.avg-aht',
          value: 0,
        },
        avgAsa: {
          theme: 'green',
          name: 'chart.card.avg-asa',
          value: 0,
        },
        totalConversations: {
          theme: 'yellow',
          name: 'chart.card.total-conversations',
          value: 0,
        },
        inbound: {
          theme: 'orange',
          name: 'chart.card.total-inbound',
          value: 0,
        },
        outbound: {
          theme: 'red',
          name: 'chart.card.total-outbound',
          value: 0,
        },
      },
      dataCharts: {
        conversationsByChannel: null,
        conversationsReceivedVsAttended: null,
        conversationsByCategory: null,
        conversationsStatuses: null,
      },
    };
  },
  watch: {
    filters(newValue) {
      if (newValue !== null) {
        this.initAll();
      }
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
  methods: {
    initAll() {
      this.loadCards();
      this.loadConversationsByChannel();
      this.loadConversationsReceivedVsAttended();
      this.loadConversationsByCategory();
      this.loadConversationsStatuses();
      this.resetFiltersFromCharts();
    },
    resetFiltersFromCharts() {
      if (this.$refs.conversationsByChannel) {
        this.$refs.conversationsByChannel.resetFilters();
      }

      if (this.$refs.conversationsReceivedVsAttended) {
        this.$refs.conversationsReceivedVsAttended.resetFilters();
      }

      if (this.$refs.conversationsByCategory) {
        this.$refs.conversationsByCategory.resetFilters();
      }

      if (this.$refs.conversationsStatuses) {
        this.$refs.conversationsStatuses.resetFilters();
      }
    },
    async loadCards() {
      const data = await this.fetchData('conversationsDataCards', ConversationsDataCards, this.filters);

      this.dataCards.avgAht.value = data.avgAht;
      this.dataCards.avgAsa.value = data.avgAsa;
      this.dataCards.totalConversations.value = data.totalConversations;
      this.dataCards.inbound.value = data.inbound;
      this.dataCards.outbound.value = data.outbound;
    },
    async loadConversationsByChannel(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.conversationsByChannel = await this.fetchData('conversationsByChannel', ConversationsByChannel, filters);
    },
    async loadConversationsReceivedVsAttended(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.conversationsReceivedVsAttended = await this.fetchData('conversationsReceivedVsAttended', ConversationsReceivedVsAttended, filters);
    },
    async loadConversationsByCategory(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.conversationsByCategory = await this.fetchData('conversationsByCategory', ConversationsByCategory, filters);
    },
    async loadConversationsStatuses(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.conversationsStatuses = await this.fetchData('conversationsStatuses', ConversationsStatuses, filters);
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    secondsDuration(value) {
      return this.$moment.duration(value, 'seconds')
        .format('h[h] m[m] s[s]');
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        const filters = {
          startDate,
          endDate,
        };

        switch (chartId) {
          case 'conversationsByChannel':
            this.loadConversationsByChannel(filters);
            break;
          case 'conversationsReceivedVsAttended':
            this.loadConversationsReceivedVsAttended(filters);
            break;
          case 'conversationsByCategory':
            this.loadConversationsByCategory(filters);
            break;
          case 'conversationsStatuses':
            this.loadConversationsStatuses(filters);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
